import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

import {
  MobileConfigProfile,
  CalDAVPayload,
  CardDAVPayload,
  RawPayload,
  generatePropertyList,
} from "@artonge/mobileconfig";
import { MatCheckboxChange } from "@angular/material/checkbox";

// https://developer.apple.com/business/documentation/Configuration-Profile-Reference.pdf
// https://yandex.com/support/mail/mail-clients/apple-mail.html

/*
  TODO:
    - + parse username from email
    - + carddav payload parameters + identifier
    - + caldav payload parameters + identifier
    - + raw payload for mail
    - + yandex docs instruction
    - calendar subscription payload?
    - test
    - + dialog with help for app passwords
    - + reenable download button after few seconds
    - whats next?
    - hint about password security
    - refactor
    - test
    - deploy on s3 bucket
*/

const CONSTANTS = {
  organisation: "Express 42",
  profileDescription: "Корпоративные сервисы Яндекс для Express 42",
  profileIdentifier: "com.express42.mobileconfig",
  mail: {
    payloadType: "com.apple.mail.managed",
    identifier: "com.express42.mobileconfig.mail",
    displayName: "Express 42 / Яндекс.Почта",
    description: "Express 42 / Яндекс.Почта IMAP",
    emailAccountType: "EmailTypeIMAP",
    incomingMailServerAuthentication: "EmailAuthPassword",
    incomingMailServerHostName: "imap.yandex.com",
    incomingMailServerPortNumber: 993,
    incomingMailServerUseSSL: true,
    outgoingPasswordSameAsIncomingPassword: true,
    outgoingMailServerAuthentication: "EmailAuthPassword",
    outgoingMailServerHostName: "smtp.yandex.com",
    outgoingMailServerPortNumber: 465,
    outgoingMailServerUseSSL: true,
    preventMove: false,
    preventAppSheet: false,
    SMIMEEnabled: false,
    allowMailDrop: false,
  },
  caldav: {
    identifier: "com.express42.mobileconfig.caldav",
    displayName: "Express 42",
    description: "Express 42 / Яндекс.Календарь CalDAV",
    hostname: "caldav.yandex.ru",
    useSSL: true,
    port: 443,
  },
  carddav: {
    identifier: "com.express42.mobileconfig.carddav",
    displayName: "Express 42",
    description: "Express 42 / Яндекс.Контакты CardDAV",
    hostname: "carddav.yandex.ru",
    useSSL: true,
    port: 443,
  },
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  name: string;
  email: string;
  username: string;
  selectedServices: string;

  isProfileDownloaded: boolean = false;
  isProfileDownloadButtonDisabled: boolean = false;

  toggleMail: boolean = true;
  toggleCalendar: boolean = true;
  toggleContacts: boolean = false;

  isMailSettingsConfigured: boolean = false;

  emailFormGroup: FormGroup;
  servicesFormGroup: FormGroup;
  mailSettingsFormGroup: FormGroup;
  passFormGroup: FormGroup;

  errorMatcher = new StepperErrorStateMatcher();

  constructor(private _formBuilder: FormBuilder, public _dialog: MatDialog) {}

  ngOnInit() {
    this.emailFormGroup = this._formBuilder.group({
      nameCtrl: ["", [Validators.required]],
      emailCtrl: [
        "",
        [
          Validators.required,
          Validators.email,
          forbiddenEmailValidator(/^([A-Za-z0-9_\-\.])+\@express42\.com$/i),
        ],
      ],
    });
    this.servicesFormGroup = this._formBuilder.group({
      servicesCtrl: ["", Validators.required],
    });
    this.mailSettingsFormGroup = this._formBuilder.group({
      settingsCtrl: ["", Validators.required],
    });
    this.passFormGroup = this._formBuilder.group({
      emailPassCtrl: ["", []],
      calendarPassCtrl: ["", []],
      contactsPassCtrl: ["", []],
    });

    // this sets some text to hidden textarea on services selection step, so we can immidietly continue
    this.fgServicesOnToggleService(null);
  }

  get fgEmail() {
    return this.emailFormGroup.controls;
  }

  get fgServices() {
    return this.servicesFormGroup.controls;
  }

  get fgMailSettings() {
    return this.mailSettingsFormGroup.controls;
  }

  get fgPass() {
    return this.passFormGroup.controls;
  }

  fgEmailOnSubmit(): void {
    this.name = this.emailFormGroup.controls.nameCtrl.value;
    this.email = this.emailFormGroup.controls.emailCtrl.value;
    this.username = this.email.split("@")[0];
  }

  fgServicesOnSubmit(): void {
    this.selectedServices = this._getSelectedServicesString();
  }

  fgPassOnSubmit(): void {}

  fgMailSettingsOnSubmit(): void {}

  fgServicesOnToggleService($event): void {
    this.fgPass.emailPassCtrl.setValidators([]);
    this.fgPass.emailPassCtrl.updateValueAndValidity();
    this.fgPass.calendarPassCtrl.setValidators([]);
    this.fgPass.calendarPassCtrl.updateValueAndValidity();
    this.fgPass.contactsPassCtrl.setValidators([]);
    this.fgPass.contactsPassCtrl.updateValueAndValidity();
    if (this.toggleMail) {
      this.fgPass.emailPassCtrl.setValidators(Validators.required);
      this.fgPass.emailPassCtrl.updateValueAndValidity();
    }
    if (this.toggleCalendar) {
      this.fgPass.calendarPassCtrl.setValidators(Validators.required);
      this.fgPass.calendarPassCtrl.updateValueAndValidity();
    }
    if (this.toggleContacts) {
      this.fgPass.contactsPassCtrl.setValidators(Validators.required);
      this.fgPass.contactsPassCtrl.updateValueAndValidity();
    }
    this.servicesFormGroup.controls.servicesCtrl.setValue(
      this._getSelectedServicesString()
    );
  }

  _getSelectedServicesString(): string {
    let selectedServices = [];
    if (this.toggleMail) {
      selectedServices.push("Почта");
    }
    if (this.toggleCalendar) {
      selectedServices.push("Календарь");
    }
    if (this.toggleContacts) {
      selectedServices.push("Контакты");
    }
    return selectedServices.join(", ");
  }

  fgMailSettingsOnConfirm(event: MatCheckboxChange): void {
    if (event.source.checked) {
      this.mailSettingsFormGroup.controls.settingsCtrl.setValue(
        "I swear that I have turned app passwords!"
      );
    } else {
      this.mailSettingsFormGroup.controls.settingsCtrl.reset();
    }
  }

  onOpenPassHelpDialog(): void {
    const dialogRef = this._dialog.open(DialogAppPasswordsHelp, {
      width: "900px",
    });
  }

  onDownloadProfile(): void {
    this.isProfileDownloaded = true;
    this.isProfileDownloadButtonDisabled = true;
    setTimeout(() => {
      this.isProfileDownloadButtonDisabled = false;
    }, 1500);
    const profile = new MobileConfigProfile({
      displayName: CONSTANTS.organisation,
      description: CONSTANTS.profileDescription,
      identifier: CONSTANTS.profileIdentifier,
      organization: CONSTANTS.organisation,
    });
    if (this.toggleMail) {
      profile.addPayload(
        new RawPayload({
          raw: {
            PayloadType: CONSTANTS.mail.payloadType,
            PayloadIdentifier: CONSTANTS.mail.identifier,
            PayloadDisplayName: CONSTANTS.mail.displayName,
            PayloadDescription: CONSTANTS.mail.description,
            PayloadOrganization: CONSTANTS.organisation,
            EmailAccountDescription: CONSTANTS.organisation,
            EmailAccountName: this.name,
            EmailAccountType: CONSTANTS.mail.emailAccountType,
            EmailAddress: this.email,
            IncomingMailServerAuthentication:
              CONSTANTS.mail.incomingMailServerAuthentication,
            IncomingMailServerHostName:
              CONSTANTS.mail.incomingMailServerHostName,
            IncomingMailServerPortNumber:
              CONSTANTS.mail.incomingMailServerPortNumber,
            IncomingMailServerUseSSL: CONSTANTS.mail.incomingMailServerUseSSL,
            IncomingMailServerUsername: this.email,
            IncomingPassword: this.fgPass.emailPassCtrl.value,
            OutgoingPasswordSameAsIncomingPassword:
              CONSTANTS.mail.outgoingPasswordSameAsIncomingPassword,
            OutgoingMailServerAuthentication:
              CONSTANTS.mail.outgoingMailServerAuthentication,
            OutgoingMailServerHostName:
              CONSTANTS.mail.outgoingMailServerHostName,
            OutgoingMailServerPortNumber:
              CONSTANTS.mail.outgoingMailServerPortNumber,
            OutgoingMailServerUseSSL: CONSTANTS.mail.outgoingMailServerUseSSL,
            OutgoingMailServerUsername: this.email,
            PreventMove: CONSTANTS.mail.preventMove,
            PreventAppSheet: CONSTANTS.mail.preventAppSheet,
            SMIMEEnabled: CONSTANTS.mail.SMIMEEnabled,
            allowMailDrop: CONSTANTS.mail.allowMailDrop,
          },
        })
      );
    }
    if (this.toggleCalendar) {
      profile.addPayload(
        new CalDAVPayload({
          identifier: CONSTANTS.caldav.identifier,
          organisation: CONSTANTS.organisation,
          accountDescription: CONSTANTS.organisation,
          displayName: CONSTANTS.caldav.displayName,
          description: CONSTANTS.caldav.description,
          hostname: CONSTANTS.caldav.hostname,
          username: this.email,
          password: this.fgPass.calendarPassCtrl.value,
          useSSL: CONSTANTS.caldav.useSSL,
          port: CONSTANTS.caldav.port,
        })
      );
    }
    if (this.toggleContacts) {
      profile.addPayload(
        new CardDAVPayload({
          identifier: CONSTANTS.carddav.identifier,
          organisation: CONSTANTS.organisation,
          accountDescription: CONSTANTS.organisation,
          displayName: CONSTANTS.carddav.displayName,
          description: CONSTANTS.carddav.description,
          hostname: CONSTANTS.carddav.hostname,
          username: this.email,
          password: this.fgPass.contactsPassCtrl.value,
          useSSL: CONSTANTS.carddav.useSSL,
          port: CONSTANTS.carddav.port,
        })
      );
    }
    // console.log(generatePropertyList(profile));
    this.downloadMobileconfig(
      generatePropertyList(profile),
      "application/octet-stream",
      this.username + ".mobileconfig"
    );

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 500);
  }

  downloadMobileconfig(content, mimeType, filename): void {
    const a = document.createElement("a");
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    a.click();
  }
}

@Component({
  selector: "dialog-app-passwords-help",
  template: `
    <h1 mat-dialog-title>Настройка паролей приложений в Яндексе</h1>
    <div mat-dialog-content>
      <p style="font-size: 0.9rem">
        1. Заходим в
        <a
          href="https://passport.yandex.ru/profile"
          target="_blank"
          style="color: #ddd"
        >
          настройки аккаунта в Яндексе. Тык по ссылке.
        </a>
      </p>
      <p style="font-size: 0.9rem">
        2. Выбираем ваш корпоративный аккаунт
        <strong>@express42.com</strong>
      </p>
      <p style="font-size: 0.9rem; line-height: 1.4rem;">
        3. Включаем Пароли приложений, если они выключены.
      </p>
      <p style="font-size: 0.9rem; line-height: 1.4rem;">
        ❗️ На следующем шаге мы будем создавать здесь пароли, оставьте вкладку
        открытой.
        <br />
        ❗️ Создаем пароль для каждого приложения, которое вы выбрали (Почта,
        Календарь).
        <br />
        ❗️❗️ Созданный <strong>пароль отображается только один раз</strong>,
        нужно сразу перенести его к нам сюда.
      </p>
      <p style="font-size: 0.9rem">
        И не забудьте выбрать корректный тип приложения
      </p>
      <img src="/assets/yandex-app-passwords-1.png" height="170" />
      &nbsp;
      <img src="/assets/yandex-app-passwords-2.png" height="170" />
      &nbsp;
      <img src="/assets/yandex-app-passwords-3.png" height="170" />
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onClickClose()">Понятно, спасибо</button>
    </div>
  `,
})
export class DialogAppPasswordsHelp {
  constructor(public dialogRef: MatDialogRef<DialogAppPasswordsHelp>) {}

  onClickClose(): void {
    this.dialogRef.close();
  }
}

// check, that app passwords are different

export class StepperErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

export function forbiddenEmailValidator(emailRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const allowed = !emailRe.test(control.value);
    return allowed ? { forbiddenEmail: { value: control.value } } : null;
  };
}
